<template>
  <div class="back-title">
    <a-icon type="arrow-left" @click="backClick" class="back-icon"/>
    <p>{{ title }}</p>
  </div>
</template>
<script>
export default ({
  name: 'BackTitle',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    backClick() {
      this.$emit('backClick')
    }
  }
})
</script>


<style scoped lang="scss">
.back-title {
  display: flex;
  align-items: center;
  .back-icon {
    font-size: 23px;
    color: #BFBFBF;
  }

  .back-icon:hover {
    transform: scale(1.11);
  }

  p {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 600;
    margin-left: 10px;
  }
}

</style>